import React from "react"

const Footer = () => {
  return (
    <div className='w-full'>
      <h1 className='bg-gray-300 text-center text-gray-500 py-2' >Pablo Godoy &copy;</h1>
    </div>
  )
}

export default Footer
